<template>
  <div>
    <Section1 :keyWord="'La mejor solución para un crédito por tu coche en Madrid'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Crédito</strong> por tu <strong>coche</strong> en <strong>Madrid</strong></h2>

      <h3 class="naranja"><strong>Crédito</strong> por tu <strong>coche</strong> en <strong>Madrid</strong></h3>
      <p class="">Existen familias que se han visto en la forzosa situación de tener que hipotecar su coche. 
      En <strong>Madrid</strong> se tiene esta alternativa de crédito que sirve como una herramienta para conseguir dinero sin tener pérdidas de un bien, 
      ni tener que seguir rogando por un préstamo bancario.</p>

      <h3 class="naranja">Empeña tu coche en <strong>Madrid</strong></h3>
      <p class="">En el mercado, existe una gran variedad de empresas en las que se pueden solicitar préstamos, <router-link to="/">Ibancar</router-link> es una de estas. 
      Una persona opta por este tipo de crédito cuando las opciones convencionales bancarias no están a su disposición o tiene la necesidad de pagar una deuda en el mismo día. 
      Normalmente, no tienen ingresos suficientes como para que el banco los considere clientes solventes, como los pensionistas y los que obtienen sus ingresos de forma autónoma. 
      Hay que tener en cuenta que <em>avalar es diferente a empeñar y tener que recomprar tu propio coche, que es la estrategia que usan algunas empresas</em></p>
      <p class="">Al elegir a <router-link to="/">Ibancar</router-link> como lugar para conseguir dinero por tu coche, <em>lograrás de forma fácil tener la cantidad estipulada en solo 24 horas.</em> 
      Para que así puedas solventar todo lo que necesitas de manera rápida y sin complicaciones. Consiguiendo <em>hasta un 60% del coche mientras conduces por <strong>Madrid</strong></em>.
      La cantidad de dinero que se va a recibir depende de la antigüedad del vehículo. <em>Se usa la valoración oficial GANVAM</em> (La Asociación Nacional de Vendedores de Vehículos a Motor, Reparación y Recambios) para medirlo. 
      Muchos usuarios se preocupan por perder la titularidad del bien mientras se paga la deuda, pero aquí eso no es un problema. 
      <em>El coche siempre será suyo</em>, y lo mejor de todo es que <em>no paga un renting por usarlo.</em> Lo único que no puede hacer es vender el coche mientras dure el préstamo con Ibancar.</p>

      <h3 class="naranja">Escoge hipotecar y vive más tranquilo</h3>
      <p class="">Para nuestra empresa, que obtengas el dinero es una urgencia, no solo un trámite más. Por eso ofrecemos los paquetes de servicio que permiten la flexibilidad necesaria para que puedas vivir sin dolores de cabeza. 
      Además y pensando en comodidad, <em>no hará falta que hagas trámites de papeleos con notarios</em>. <em>Todo el proceso es 100% online y lo puedes hacer desde tu casa.</em></p>
      <p class="">Nos preocupamos porque puedas salir adelante y sigas con tus planes de vida. No dudes de hacer este gran paso y serás uno de nuestros miles de clientes satisfechos. 
      Escoge <router-link to="/">Ibancar</router-link> para hipotecar tu coche por dinero.</p>

    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>
<!-- fila landing 1  -->
      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
<!-- fila landing 3  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
              <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
                <div class="card-body">
                  <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                    <span class="caret-sign-city">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                      </svg>
                    </span>
                  <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
                </div>
              </a>
          </div>
      </div> 
<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>


      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Crédito por tu coche en Madrid ▷ 100% online',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Consigue crédito por tu coche en Madrid ¿Necesitas conseguir dinero rápido con tu coche? Entra aquí, te explicamos con lujos y detalles todo lo que necesitas saber para adquirir una financiación.'},
        {name:'keywords', content:'Dinero rápido con tu coche, Préstamo rápido y fácil, Conseguir dinero fácil.'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>